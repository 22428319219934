import React from "react"
import Hero from "./hero"

const BlockHero = ({ data }) => {
  //console.log("Block Hero: ", data)
  return (
    <>
      <Hero hero={data} />
    </>
  )
}

export default BlockHero
