import React from "react"
import CtaSlider from "./cta-slider"

const BlockCta = ({ data }) => {
  //console.log("Block CTA: ", data)

  const {
    title,
    description,
    call_to_action_sections,
    bg = "uk-section-muted",
    //location,
    image,
    paypalButton,
  } = data

  return (
    <>
      <CtaSlider
        title={title}
        description={description}
        callToActionSections={call_to_action_sections}
        bg={bg}
        image={image}
        paypalButton={paypalButton}
      />
    </>
  )
}

export default BlockCta
