import React from "react"
import PageAdvert from "./page-advert"

const BlockAdvert = ({ data }) => {
  //console.log("Block Advert: ", data)

  const blockAdvert = data.page_adverts[0]
  const bg = data.page_adverts[0].bg

  return <PageAdvert advert={blockAdvert} bg={bg} />
}

export default BlockAdvert
