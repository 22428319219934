import React from "react"
import { graphql } from "gatsby"
import BlockRichText from "./block-rich-text"
import BlockMedia from "./block-media"
import BlockAdvert from "./block-advert"
import BlockQuote from "./block-quote"
import BlockSlider from "./block-slider"
import BlockGallery from "./block-gallery"
import BlockHero from "./block-hero"
import BlockHeadings from "./block-headings"
import BlockCta from "./block-cta"
import BlockVideo from "./block-video"

const componentsMap = {
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_SHARED_MEDIA: BlockMedia,
  STRAPI__COMPONENT_SHARED_PAGE_AD: BlockAdvert,
  STRAPI__COMPONENT_SHARED_QUOTE: BlockQuote,
  STRAPI__COMPONENT_SHARED_SLIDER: BlockSlider,
  STRAPI__COMPONENT_SHARED_GALLERY: BlockGallery,
  STRAPI__COMPONENT_SHARED_HERO: BlockHero,
  STRAPI__COMPONENT_SHARED_HEADING: BlockHeadings,
  STRAPI__COMPONENT_SHARED_CALL_TO_ACTION: BlockCta,
  STRAPI__COMPONENT_SHARED_VIDEO: BlockVideo,
}

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  return <Component data={block} />
}

const BlocksRenderer = ({ blocks }) => {
  //console.log("Blocks: ", blocks)
  return (
    <React.Fragment>
      {blocks.map((block, index) => (
        <Block key={`${index}${block.__typename}`} block={block} />
      ))}
    </React.Fragment>
  )
}

export const query = graphql`
  fragment Blocks on STRAPI__COMPONENT_SHARED_CALL_TO_ACTIONSTRAPI__COMPONENT_SHARED_GALLERYSTRAPI__COMPONENT_SHARED_HEADINGSTRAPI__COMPONENT_SHARED_HEROSTRAPI__COMPONENT_SHARED_MEDIASTRAPI__COMPONENT_SHARED_PAGE_ADSTRAPI__COMPONENT_SHARED_QUOTESTRAPI__COMPONENT_SHARED_RICH_TEXTSTRAPI__COMPONENT_SHARED_SLIDERSTRAPI__COMPONENT_SHARED_VIDEOUnion {
    __typename
    ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
      richTextBody: body {
        __typename
        data {
          id
          body
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_MEDIA {
      file {
        alternativeText
        name
        mime
        localFile {
          #url
          publicURL
          childImageSharp {
            gatsbyImageData(aspectRatio: 2.1)
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_PAGE_AD {
      ...PageAdverts
    }
    ... on STRAPI__COMPONENT_SHARED_QUOTE {
      title
      quoteBody: body
    }
    ... on STRAPI__COMPONENT_SHARED_SLIDER {
      files {
        id
        alternativeText
        caption
        mime
        localFile {
          url
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.57)
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_GALLERY {
      title
      description
      bg
      files {
        id
        alternativeText
        caption
        mime
        localFile {
          url
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              # aspectRatio: 1.80
            )
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_HERO {
      ...StrapiHero
    }

    ... on STRAPI__COMPONENT_SHARED_HEADING {
      ...StrapiHeading
    }

    ... on STRAPI__COMPONENT_SHARED_CALL_TO_ACTION {
      ...StrapiCallToAction
    }

    ... on STRAPI__COMPONENT_SHARED_VIDEO {
      headings {
        title
        description
      }
      src
      title
      width
      height
    }
  }
`

export default BlocksRenderer
