import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlockMedia = ({ data }) => {
  //console.log("Data: ", data)
  const isVideo = data.file.mime.startsWith("video")

  //console.log(data.file)
  //const video = data.file.name
  //console.log("Video: ", video)

  return (
    <div className="uk-section uk-section-default uk-padding-remove uk-margin-small-bottom">
      <div className="uk-container ">
        {/*  <h4 className="uk-margin">{data.file.alternativeText}</h4> */}
        {isVideo ? (
          <p>TODO video</p>
        ) : (
          <GatsbyImage
            image={getImage(data.file.localFile)}
            alt={data.file.alternativeText}
            className=" uk-margin-small-bottom"
          />
        )}
      </div>
    </div>
  )
}

export default BlockMedia
