import React from "react"
import Headings from "./headings"

const BlockHeadings = ({ data }) => {
  //console.log("Block HHeadings: ", data)

  const { title, description, bg } = data

  return (
    <>
      <Headings title={title} description={description} bg={bg} />
    </>
  )
}

export default BlockHeadings
